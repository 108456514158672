import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import styled from "styled-components"
import Breakpoints from "../utils/breakpoints"

const BlogPostItem = styled.li`
  :not(:last-child) {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 8px solid #000;
  }
`

const BlogPostArticle = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${+Breakpoints.mobile + 1}px) {
    flex-direction: row;
  }
`

const FeaturedImageContainer = styled.div`
  flex: 1;
  max-height: 300px;
  overflow: hidden;
`

const BlogPostContent = styled.div`
  padding: 0.5rem;
  flex: 1;
  @media (min-width: ${+Breakpoints.mobile + 1}px) {
    padding: 2rem;
  }
`

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <>
        <Seo title="Keine Beiträge" />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </>
    )
  }

  return (
    <>
      <Seo title="Alle Beiträge" />
      <h1>Blog</h1>
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.title
          const featuredImage = {
            data:
              post.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
            alt: post.featuredImage?.node?.alt || ``,
          }
          return (
            <BlogPostItem key={post.uri}>
              <BlogPostArticle
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <FeaturedImageContainer>
                  {featuredImage?.data && (
                    <Link to={post.uri} itemProp="url">
                      <GatsbyImage
                        image={featuredImage.data}
                        alt={featuredImage.alt}
                      />
                    </Link>
                  )}
                </FeaturedImageContainer>
                <BlogPostContent>
                  <header>
                    <h2>
                      <Link to={post.uri} itemProp="url">
                        <span itemProp="headline">{parse(title)}</span>
                      </Link>
                    </h2>
                    <small>{post.date}</small>
                  </header>
                  <section itemProp="description">
                    {parse(post.excerpt)}
                  </section>
                </BlogPostContent>
              </BlogPostArticle>
            </BlogPostItem>
          )
        })}
      </ol>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY", locale: "de")
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
